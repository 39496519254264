.react-datepicker {
  @apply p-4 font-sans text-xs text-gray-900 border border-solid border-gray-200 rounded-lg;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  @apply border-b-white;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  @apply border-b-gray-200;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: var(--border-radius-lg);
}

.react-datepicker__header {
  @apply bg-white border-b-0 py-1 px-0;
  // border-top-left-radius: var(--border-radius-lg);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @apply text-gray-900 font-bold text-sm;
}

.react-datepicker__current-month {
  margin-top: 2px;
}

.react-datepicker__navigation {
  @apply top-4 h-8 w-8 rounded hover:bg-gray-50;
}

.react-datepicker__navigation-icon {
  margin-top: -3px;
}

.react-datepicker__navigation-icon--previous::before {
  right: -5px;
}

.react-datepicker__navigation-icon--next::before {
  left: -5px;
}

.react-datepicker__navigation--previous {
  left: 16px;
}

.react-datepicker__navigation--next {
  right: 16px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  height: 7px;
  width: 7px;
  top: 8px;
}

.react-datepicker__header__dropdown {
  @apply py-4;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  @apply text-gray-900;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  @apply bg-gray-100 rounded;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply bg-primary-600 hover:bg-primary-700 text-white rounded;
}

.react-datepicker__day--disabled {
  @apply text-gray-400;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  @apply bg-primary-50 hover:bg-primary-100 text-primary-600;
}
